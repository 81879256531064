.h4 {
  font-size: 40px !important;
}

.labels {
  font-size: 24px !important;
}

p.title {
  font-size: 22px;
}

address {
  font-size: 16px;
}

p.gtsin {
  font-size: 16px;
  font-weight: 500;
}

p.admin-name {
  font-size: 22px;
}

p.phone {
  font-size: 16px;
}

p.email {
  font-size: 16px;
}

.card--text {
  padding: 0 5px !important;
}

[type="checkbox"] {
  margin-left: 16px;
  margin-top: 16px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.inputCard {
  box-shadow: 0px 0px 0px;
  padding: 0rem 1rem 0rem 1rem !important;
  border-radius: 0% !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.loginContainer {
  display: table !important;
}

.loginContainerForm {
  display: table-cell;
  vertical-align: middle;
}

.form-box {
  max-width: 500px;
  margin: auto;
  padding: 50px;
  background: #ffffff;
  border: 2px solid #f2f0f0;
}

.form-group {
  margin-bottom: 15px;
}

.completedPageForm {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.cardHeight {
  min-height: 120px !important;
}

.completedPageCard {
  min-height: auto !important;
  border: 0 !important;
  background: #ffffff !important;
  width: 220px;
  /* height: 220px; */
  padding: 0% !important;
  border-radius: 1rem !important;
  margin: 1rem auto !important;
  transition: all 0.2s ease-in !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  text-align: center !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.subOrdinateCheckbox {
  margin: 0px !important;
}

.noData {
  margin-top: 5rem;
  text-align: center;
}

@media (max-width: 767px) {
  .noData {
    margin: 75px 20px;
  }

  .payment_amount_div {
    width: 100%;
    margin-bottom: 15px;
  }
  .actual_collected_amount_div {
    width: 100%;
  }
}

@media (min-width: 767px) {
  .payment_amount_div {
    width: 49%;
  }
  .actual_collected_amount_div {
    width: 49%;
  }
}

.viewEditLink {
  color: black !important;
}

.searchField {
  outline: 0;
  float: right;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.searchField > .searchTextbox {
  outline: 0;
  height: 42px;
  width: 244px;
  line-height: 42px;
  padding: 0 16px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #212121;
  border: 0;
  float: left;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.searchField > .searchTextbox:focus {
  outline: 0;
  background-color: #fff;
}

.searchField > .searchButton {
  outline: 0;
  background: none;
  background-color: rgba(38, 50, 56, 0.8);
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  float: left;
  height: 42px;
  width: 42px;
  text-align: center;
  line-height: 42px;
  border: 0;
  color: #fff;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.searchField > .searchButton:hover {
  background-color: rgba(0, 150, 136, 0.8);
}

html,
body {
  height: 100%;
}

/*  */

.table-width {
  min-width: max-content;
  /* max-width: max-content; */
}

/*  */

@media screen and (max-width: 740px) {
  .searchField {
    float: left;
  }
  .searchField > .searchTextbox {
    /* width: 84vw; */
  }
}

@media (max-width: 991px) {
  .navbar-toggler {
    z-index: 10;
    outline: none !important;
  }
  .navbar-collapse {
    position: absolute;
    top: 0px;
    left: 100%;
    width: 80%;
    transition: all 0.2s ease-in-out;
    display: block;
    background-color: #f8f9fa;
    padding: 100px 50px;
  }
  .navbar-collapse.collapsing {
    height: auto !important;
    margin-left: 50%;
    left: 50%;
    transition: all 0.2s ease-in-out;
  }
  .navbar-collapse.show {
    left: 20%;
    z-index: 9;
    height: 100vh;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 12px;
}
::-webkit-scrollbar:horizontal {
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 2px solid #ffffff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.nav,
.nav-tabs {
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: end;
  overflow-x: auto;
  overflow-y: hidden !important;
}
.nav-item {
  min-width: fit-content;
}
.nav,
.nav-tabs > .nav-item {
  padding-top: 0.3rem;
  border-bottom: 1px solid #dee2e6;
}
.nav,
.nav-tabs > .nav-item:hover {
  border-bottom: none;
}

.nav,
.nav-tabs {
  border: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.item-capsule {
  display: inline-block;

  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 8px;
  margin: 5px;
}
.item-capsule-accordian {
  display: inline-block;
  align-items: center;
  background-color: blue;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 8px;
  margin: 5px;
}
.item-capsule {
  display: inline-block;
  align-items: center;
  border: 1px solid #ccc;
  background-color: whitesmoke;
  border-radius: 15px;
  padding: 8px;
  margin: 5px;
}
.item-text-accordian {
  color: white;
}
.item-text {
  color: black;
}
.time_checkbox {
  margin-top: 5px;
  margin-left: 2px;
}

.item-text {
  margin-right: 8px;
}

.bi-x {
  width: 5%;
  /* padding: 10px; */
  margin: 5px;
  cursor: pointer;
  color: #ff0000; /* or any color you prefer */
}

.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-box {
  display: flex;
  flex-wrap: wrap;
}

.image-preview {
  width: 100px;
  height: 100px;
  margin: 5px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#front-file-input {
  display: none;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 2px dashed #ccc;
  width: 130px;
  height: 120px;
  cursor: pointer;
  /* margin: end; *0 */
}

.highlight {
  background-color: rgb(
    113,
    21,
    132
  ); /* Adjust the highlight color as needed */
}

/* .form-box-width {
  align-items: center;
  margin: 2px;
  padding: 50px;
  background: #ffffff;
  border: 2px solid #f2f0f0;
  font-size: small;
} */

/* .form-box-width {
  align-items: center;
  margin: 2px;
  padding: 20px;
  background: #ffffff;
  border: 2px solid #f2f0f0;
  font-size: small;
} */

.modal-title-custom {
  font-size: 25px;
}

.line {
  background-color: #212121;
  border: lightslategrey;
}

.bi-plus-circle {
  font-size: x-large;
}
.btn-link,
.nav-link:focus {
  box-shadow: none !important;
}
.btn-link,
.nav-link {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.top-right-modal > div {
  position: absolute !important;
  top: -24px !important;
  right: 4px !important;
  width: -webkit-fill-available !important;
}

@media only screen and (max-width: 600px) {
  .top-right-modal > div {
    top: 0px !important;
  }
  .text-small {
    font-size: 12px;
    line-height: 13px !important;
  }
  .swal2-shown,
  .swal2-height-auto {
    padding-right: 0 !important;
  }
  .hidden-card {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.8s;
    filter: blur(4px);
  }
  .show-card {
    opacity: 1;
    transform: translateX(0%);
    filter: blur(0);
  }
}

.click-animation {
  transition: all ease-out 0.5s;
}

.click-animation:active {
  transform: scale(0.9);
}

.anb-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

/* search Image meratractor */

body {
  background-color: #f5f5f5;
}

.imagePreview {
  width: 100%;
  height: 240px;
  /* background-position: center center; */
  /* background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg); */
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}

.btn-primary {
  width: "100%";
}

.imgUp {
  margin-bottom: 15px;
}

.del {
  position: absolute;
  top: 0px;
  right: 15px;

  text-align: center;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

.imgAdd {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4bd7ef;
  color: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 30px;
  margin-top: 0px;
  cursor: pointer;
  font-size: 15px;
}
.text-align-start {
  text-align: start;
}
/* .alert-secondary {
  height: 30px;
  margin: 0%;
  margin-bottom: 10px;
  display: "flex";
  flex-wrap: wrap;
} */

/* @media (max-width: 320px) {
  .alert-secondary {
    height: 50px;
  }
} */
/* @media (max-width: 480px) {
  .alert-secondary {
    height: 50px;
  }
} */

.text-small {
  font-size: 14px !important;
  line-height: 14px !important;
}
.text-sm {
  font-size: 12px !important;
  line-height: 12px !important;
}

.btn-saffron {
  background-color: #fd7e14 !important;
  color: #dee2e6 !important;
}

.btn-saffron:hover {
  background-color: #ca6510 !important;
}

.mechanization-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/public/women_driving_tractor.webp") no-repeat center center;
  background-size: cover;
  z-index: -1;
  /* opacity: 0.9; */
  border-radius: 0.375rem;
}

.accordion .accordion-header {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.accordion .accordion-button {
  background-color: #ffffff;
  color: #ffffff;
  border: none;
}

.accordion .accordion-button:not(.collapsed) {
  background-color: #ffffff;
}

.accordion .accordion-button:hover {
  background-color: #ecf1f7;
}

.accordion .accordion-button:focus {
  box-shadow: none; /* Remove focus outline */
}

.accordion .accordion-collapse .form-box {
  margin-left: 1rem; /* Adjust checkbox spacing */
}

.pagination .page-link {
  color: #007bff; /* Pagination link color */
}

.pagination .page-link:hover {
  color: #0056b3; /* Hover color */
}

.pagination .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: #ffffff;
}

.accordion-button {
  color: #000 !important;
}

.accordion-button:not(.collapsed) {
  background-color: #f8f9fa;
  color: #000 !important;
  border-color: #ddd;
}

.accordion-button:focus,
.accordion-button:hover {
  color: #000 !important; /* Keep text black on hover and focus */
}

.suggestion-box {
  /* position: absolute; */
  top: 18rem;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.arrow-circle {
  display: inline-block;
  border-radius: 50%;
  /* padding: 10px; */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.arrow-circle:hover {
  background-color: rgb(167, 148, 242); /* Circle color on hover */
  transform: scale(0.9); /* Slightly increase the size on hover */
}

.arrow-circle:active {
  background-color: rgba(194, 153, 244, 0.633); /* Circle color on click */
}

/* yard owner card */
.card-with-count {
  background: url("/public/ellipse_for_card.svg") no-repeat top center;
  /* background-position: -200px; */
  height: 220px !important;
  width: 220px !important;
  background-size: contain;
  transition: all 0.2s ease-in-out;
}
.card-with-count:active {
  transform: scale(0.9);
}
.card-with-count:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important ;
}

@media (max-width: 768px) {
  .card-with-count {
    width: 100px !important;
    height: 100px !important;
  }

  .card-with-count span {
    font-size: 0.8rem !important;
  }
}

.bg-danger-600 {
  background-color: #ed1c24 !important;
}
.bg-danger-50 {
  background-color: #fff1f2 !important;
}
.bg-danger-100 {
  background-color: #ffdfe0 !important;
}

/* MT Colors */

:root {
  --mt-red-extra-dark: #a41016;
  --mt-red-dark: #a41016;
  --mt-red-regular: #ed1c24;
  --mt-red-light: #ff9da1;
  --mt-red-extra-light: #ffc5c7;
  --mt-red-bg: #fff1f2;
}

/* Background Color */
.mt-bg-red-extra-dark {
  background-color: var(--mt-red-extra-dark) !important;
}

.mt-bg-red-dark {
  background-color: var(--mt-red-dark) !important;
}

.mt-bg-red-regular {
  background-color: var(--mt-red-regular) !important;
}

.mt-bg-red-light {
  background-color: var(--mt-red-light) !important;
}

.mt-bg-red-extra-light {
  background-color: var(--mt-red-extra-light) !important;
}

.mt-bg-red-bg {
  background-color: var(--mt-red-bg) !important;
}

/* Text */
.mt-text-red-extra-dark {
  color: var(--mt-red-extra-dark) !important;
}

.mt-text-red-dark {
  color: var(--mt-red-dark) !important;
}

.mt-text-red-regular {
  color: var(--mt-red-regular) !important;
}

.mt-text-red-light {
  color: var(--mt-red-light) !important;
}

.mt-text-red-extra-light {
  color: var(--mt-red-extra-light) !important;
}

.mt-text-red-bg {
  color: var(--mt-red-bg) !important;
}

/* Border */
.mt-border-red-extra-light {
  border-color: var(--mt-red-extra-light) !important;
  border-width: 2px !important;
}

/* Font Size */

:root {
  --min-font: 1rem;
  --max-font: 2rem;
  --fluid-font: clamp(var(--min-font), 1.5vw + 0.5rem, var(--max-font));
}

.text {
  font-size: var(--fluid-font);
}
.cursor-pointer {
  cursor: pointer !important;
}

.heading5 {
  font-size: 25px;
  color: black;
  border-bottom: 1px solid grey;
  max-width: 90%;
  word-wrap: break-word;
}
